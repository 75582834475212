import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC, useEffect } from 'react';
import Switch from '@components/molecules/Switch/Switch';
import { graphql, useStaticQuery } from 'gatsby';

const StyledWrapper = styled.div`
  @media print {
    display: flex;
    flex-direction: column;
    order: 99999999;
  }
`;

interface StyledProps {
  $isActive: boolean;
}

const StyledGatsbyImagesWrapper = styled.div`
  margin: 40px auto;
  width: 100%;
  max-width: 620px;
  max-height: 620px;
  height: 100vw;
  position: relative;

  @media (min-width: 1025px) {
    width: 45vw;
    height: 45vw;
    max-width: 885px;
    max-height: 885px;
  }

  @media (max-width: 365px) {
    margin: 10px auto;
  }

  @media print {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: unset;
    max-height: unset;
    max-width: unset;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)<StyledProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
    `}

  @media print {
    opacity: 1 !important;
    position: relative;
    height: auto;
  }
`;

const VisAnd2DView: FC<Props> = ({
  currentFloor,
  setCurrentFloor,
  currentView,
  setCurrentView,
  ...props
}) => {
  const data = useStaticQuery(query);

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('beforeprint', (event) => {});
  }, []);

  return (
    <StyledWrapper {...props}>
      <Switch
        firstText="Wizualizacja"
        secondText="Rzut 2D"
        currentValue={currentView}
        setCurrentValue={setCurrentView}
        id="visType"
      />

      <StyledGatsbyImagesWrapper>
        <StyledGatsbyImage
          image={getImage(data.View3DFirstFloor)}
          alt=""
          $isActive={currentFloor === 0 && currentView == 0}
          className="img"
          objectFit="contain"
          loading="eager"
        />

        <StyledGatsbyImage
          image={getImage(data.View3DSecondFloor)}
          alt=""
          $isActive={currentFloor === 1 && currentView == 0}
          className="img"
          objectFit="contain"
          loading="eager"
        />

        <StyledGatsbyImage
          image={getImage(data.View2DFirstFloor)}
          alt=""
          $isActive={currentFloor === 0 && currentView == 1}
          className="img"
          objectFit="contain"
          loading="eager"
        />

        <StyledGatsbyImage
          image={getImage(data.View2DSecondFloor)}
          alt=""
          $isActive={currentFloor === 1 && currentView == 1}
          className="img"
          objectFit="contain"
          loading="eager"
        />
      </StyledGatsbyImagesWrapper>

      <Switch
        firstText="Parter"
        secondText="Poddasze"
        currentValue={currentFloor}
        setCurrentValue={setCurrentFloor}
        id="visType2"
      />
    </StyledWrapper>
  );
};

interface Props {
  currentFloor: 0 | 1;
  setCurrentFloor: React.Dispatch<React.SetStateAction<0 | 1>>;
  currentView: 0 | 1;
  setCurrentView: React.Dispatch<React.SetStateAction<0 | 1>>;
}

const query = graphql`
  {
    View2DFirstFloor: file(name: { eq: "2dview_first-floor" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View2DSecondFloor: file(name: { eq: "2dview_second-floor" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DFirstFloor: file(name: { eq: "3dview_first-floor" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DSecondFloor: file(name: { eq: "3dview_second-floor" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default VisAnd2DView;
