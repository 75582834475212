import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface StyledProps {
  $secondary: boolean;
}

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  font-size: ${({ theme }) => theme.bodyXS};
  break-inside: avoid;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyXL};
  }

  @media (min-width: 1280px) {
    font-size: ${({ theme }) => theme.bodyXXL};
  }
`;

const StyledItem = styled.li<StyledProps>`
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.gray200};
  display: flex;
  justify-content: space-between;
  position: relative;
  break-inside: avoid;

  @media (min-width: 1025px) {
    padding: 11px 0;
  }

  ${({ $secondary }) =>
    $secondary &&
    css`
      padding: 12px 0 12px 30px;
      :last-of-type {
        border-bottom: 0;
      }

      @media (min-width: 1025px) {
        padding: 13px 0 13px 50px;
        font-size: ${({ theme }) => theme.bodyL};
      }

      @media (min-width: 1280px) {
        padding: 13px 0 13px 50px;
      }
    `}
`;

const StyledValue = styled.span`
  font-weight: ${({ theme }) => theme.bold};
  margin-left: 10px;
`;

const StyledCounter = styled.div`
  color: ${({ theme }) => theme.gray200};
  margin-right: 15px;
  font-weight: ${({ theme }) => theme.bold};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Table: FC<Props> = ({ items, secondary, className }) => (
  <StyledList className={className}>
    {items.map(({ value, name }, index) => (
      <StyledItem $secondary={secondary} key={index}>
        {secondary && (
          <StyledCounter>
            {index < 9 ? `0${index + 1}` : index + 1}
          </StyledCounter>
        )}
        <span>{name}</span>
        <StyledValue>{value.replace('.', ',')}</StyledValue>
      </StyledItem>
    ))}
  </StyledList>
);

interface Props {
  items: Item[];
  secondary?: boolean;
  className?: string;
}

interface Item {
  name: string;
  value: string;
}

export default Table;
