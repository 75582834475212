import React, { FC, useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface StyledProps {
  $isActive?: boolean;
  $currentValue?: number;
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  flex-wrap: wrap;

  @media (max-width: 365px) {
    flex-direction: column;
    justify-content: center;
  }

  @media print {
    display: none;
  }
`;

const StyledValue = styled.span<StyledProps>`
  font-size: ${({ theme }) => theme.bodyL};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.gray200};
  display: block;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.foreground};
    `};

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.headingXXS};
  }
`;

const StyledButton = styled.button<StyledProps>`
  width: 60px;
  height: 28px;
  border-radius: 15px;
  background: ${({ theme }) => theme.primary};
  border: 0;
  outline: 0;
  position: relative;
  margin: 0 30px;
  cursor: pointer;

  @media (max-width: 365px) {
    margin: 15px 0;
  }

  span {
    position: absolute;
    display: block;
    top: 50%;
    left: 4px;
    transform: ${({ $currentValue }) =>
      `translate(${$currentValue === 0 ? '0' : 'calc(52px - 100%)'}, -50%)`};
    transition: transform 0.2s ease-in-out;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.background};
  }

  @media (min-width: 1025px) {
    width: 65px;
    height: 30px;

    span {
      width: 22px;
      height: 22px;
      transform: ${({ $currentValue }) =>
        `translate(${$currentValue === 0 ? '0' : 'calc(56px - 100%)'}, -50%)`};
    }
  }
`;

const Switch: FC<Props> = ({
  firstText,
  secondText,
  currentValue,
  setCurrentValue,
  id,
  className,
}) => {
  const [initialValue, setInitialValue] = useState<0 | 1>();

  useLayoutEffect(() => {
    setInitialValue(currentValue);
  }, []);

  return (
    <StyledWrapper className={className}>
      <StyledValue
        $isActive={currentValue === 0}
        onClick={() => setCurrentValue(0)}
      >
        {firstText}
      </StyledValue>
      <StyledButton
        role="switch"
        aria-checked={currentValue !== initialValue}
        id={id}
        onClick={() => setCurrentValue(currentValue === 0 ? 1 : 0)}
        $currentValue={currentValue}
        aria-label={currentValue === 0 ? secondText : firstText}
      >
        <span />
      </StyledButton>
      <StyledValue
        $isActive={currentValue === 1}
        onClick={() => setCurrentValue(1)}
      >
        {secondText}
      </StyledValue>
    </StyledWrapper>
  );
};

interface Props {
  firstText: string;
  secondText: string;
  currentValue: 0 | 1;
  setCurrentValue: (currentValue: 0 | 1) => void;
  id: string;
  className?: string;
}

export default Switch;
